import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { getBuildings } from "../actions/getBuildings";
import $ from "jquery";
import { getBuldingSuccess } from "../actions/tickets";
import { connect } from "react-redux";
import querystring from "query-string";
import { getAclChecks } from "../utilities";

$("#collapsibleNavbar").hover(
  function () {
    $("#sidenav").addClass("menuHover");
  },
  function () {
    $("#sidenav").removeClass("menuHover");
  }
);

class Sidebar extends Component {
  constructor(props) {
    var params = querystring.parse(decodeURIComponent(window.location.search));
    super(props);
    this.state = {
      menuDisplay: props.menuDisplay,
      buildings: [],
    };
  }

  componentDidMount() {
    this.getBuildings();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.menuDisplay !== prevState.menuDisplay) {
      return { menuDisplay: nextProps.menuDisplay };
    } else {
      return null;
    }
  }

  toggleMenuNames() {
    var element = document.getElementById("sidenav");
    element.classList.toggle("show");

    var element = document.getElementById("hamburger-icon");
    element.classList.toggle("open-nav");

    var element = document.getElementById("header-panel");
    element.classList.toggle("menu-name-close");

    var element = document.getElementById("sidenav");
    element.classList.toggle("menu-name-close");

    var element = document.getElementById("wrapper");
    element.classList.toggle("menu-name-close");
  }

  newPage() {
    $("#sidenav").removeClass("menu-name-close");
    $("#header-panel").removeClass("menu-name-close");
    $("#wrapper").removeClass("menu-name-close");
  }

  getBuildings() {
    let data = {
      pageNumber: 1,
    };
    getBuildings(data).then((response) => {
      if (
        response.data.responseData &&
        response.data.responseData.data &&
        response.data.responseData.data[0]
      ) {
        this.setState({ buildings: response.data.responseData.data });
      }
    });
  }

  changeBuildingId(id) {
    sessionStorage.setItem("buildingId", id);
    this.props.getBuldingSuccess(id);
    //window.location.reload();
  }

  render() {
    const { buildings } = this.state;
    const { pathname } = this.props.location;
    console.log("pathname.indexO", pathname);

    console.log("=====aaa==a==a==a=a==", getAclChecks("create-tenant-blog"));

    return (
      <div>
        <nav
          id="sidenav"
          className={`${"sidenav navbar navbar-expand navbar-light"} ${
            this.state.menuDisplay ? "show" : ""
          }`}
        >
          <NavLink
            to="/landing"
            className="navbar-brand"
            activeClassName="active"
          >
            <img src="/assets/images/admin-logo.svg" alt="img" />
          </NavLink>
          <button
            className="btn-close"
            onClick={() => this.toggleMenuNames()}
          ></button>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav">
              <li className="nav-item" onClick={() => this.newPage()}>
                <a className="nav-link" href="/landing">
                  <i className="icon home-icon"></i>
                  <span className="name">Home</span>
                </a>
              </li>
              {buildings &&
                buildings.length > 0 &&
                buildings.map((obj, index) => (
                  <li
                    key={index}
                    className="nav-item level-one-menu"
                    onClick={() => this.newPage()}
                  >
                    <button
                      onClick={() => this.changeBuildingId(obj.id)}
                      className={`${"nav-link active dropdown-toggle"} ${
                        obj.id ==
                        JSON.parse(sessionStorage.getItem("buildingId"))
                          ? ""
                          : "collapsed"
                      }`}
                      data-toggle="collapse"
                      data-target={`#loc${obj.id}`}
                    >
                      <i className="icon location-icon"></i>
                      <span className="name text-ellipes">{obj.name}</span>
                    </button>
                    <div
                      id={`loc${obj.id}`}
                      onClick={() => this.changeBuildingId(obj.id)}
                      className={`${"d-menu collapse"} ${
                        obj.id ==
                        JSON.parse(sessionStorage.getItem("buildingId"))
                          ? "show"
                          : ""
                      }`}
                    >
                      <ul>
                        <li className="dropdown-item">
                          <NavLink
                            to={`/dashboard/overview?buildingId=${obj?.id}`}
                            className="dropdown-link"
                            activeClassName="active"
                          >
                            <i className="icon-2 overview-icon"></i>
                            <span className="name">Overview</span>
                          </NavLink>
                        </li>
                        {/* <li className="dropdown-item">
                            <NavLink to="/dashboard/blog-news" className="dropdown-link" activeClassName="active">
                              <i className="icon-2 news-icon"></i>
                              <span className="name">News</span>
                            </NavLink>
                        </li> */}

                        {getAclChecks("create-tenant-blog") ||
                        getAclChecks("create-tenant-poll") ||
                        getAclChecks("create-tenant-event") ||
                        getAclChecks("manage-blogs") ||
                        getAclChecks("manage-polls") ||
                        getAclChecks("manage-events") ? (
                          <li className="dropdown-item level-three-menu">
                            <button
                              className="dropdown-link  dropdown-toggle collapsed"
                              data-toggle="collapse"
                              data-target={`#news${JSON.parse(
                                sessionStorage.getItem("buildingId")
                              )}`}
                            >
                              <i className="icon-2 news-icon"></i>
                              <span className="name">News/Events</span>
                            </button>
                            <div
                              id={`news${JSON.parse(
                                sessionStorage.getItem("buildingId")
                              )}`}
                              className="d-menu collapse"
                            >
                              <ul>
                                {getAclChecks("create-tenant-blog") ||
                                getAclChecks("manage-blogs") ? (
                                  <li className="dropdown-item">
                                    <NavLink
                                      to="/dashboard/blog-news/feed"
                                      className="dropdown-link"
                                      activeClassName="active"
                                    >
                                      <span className="name">News</span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {getAclChecks("create-tenant-poll") ||
                                getAclChecks("manage-polls") ? (
                                  <li className="dropdown-item">
                                    <NavLink
                                      to="/dashboard/poll"
                                      className="dropdown-link"
                                      activeClassName="active"
                                    >
                                      <span className="name">Polls</span>
                                    </NavLink>
                                  </li>
                                ) : null}

                                {getAclChecks("create-tenant-event") ||
                                getAclChecks("manage-events") ? (
                                  <li className="dropdown-item">
                                    <NavLink
                                      to="/dashboard/event"
                                      className="dropdown-link"
                                      activeClassName="active"
                                    >
                                      <span className="name">Events</span>
                                    </NavLink>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </li>
                        ) : null}

                        {getAclChecks("create-shop") ||
                        getAclChecks("create-tenant-handbook") ||
                        getAclChecks("view-news") ||
                        getAclChecks("create-parcels") ? (
                          <li className="dropdown-item level-three-menu">
                            <button
                              className="dropdown-link  dropdown-toggle collapsed"
                              data-toggle="collapse"
                              data-target={`#perksHandbook${JSON.parse(
                                sessionStorage.getItem("buildingId")
                              )}`}
                            >
                              <i className="icon-2 services-icon"></i>
                              <span className="name">Services</span>
                            </button>
                            <div
                              id={`perksHandbook${JSON.parse(
                                sessionStorage.getItem("buildingId")
                              )}`}
                              className="d-menu collapse"
                            >
                              <ul>
                                {getAclChecks("create-shop") ? (
                                  <li className="dropdown-item">
                                    <NavLink
                                      to="/dashboard/shops"
                                      className="dropdown-link"
                                      activeClassName="active"
                                    >
                                      <span className="name">Shops</span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {getAclChecks("view-news") ? (
                                  <li className="dropdown-item">
                                    <NavLink
                                      to="/dashboard/perks"
                                      className="dropdown-link"
                                      activeClassName="active"
                                    >
                                      <span className="name">Perks</span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {getAclChecks("create-tenant-handbook") ||
                                getAclChecks("view-news") ? (
                                  <li className="dropdown-item">
                                    <NavLink
                                      to="/dashboard/tenant-handbook"
                                      className="dropdown-link"
                                      activeClassName="active"
                                    >
                                      <span className="name">
                                        Tenant Hanbook
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {getAclChecks("create-parcels") ? (
                                  <li className="dropdown-item">
                                    <NavLink
                                      to="/dashboard/parcels"
                                      className="dropdown-link"
                                      activeClassName="active"
                                    >
                                      {/* <i className="icon-2 reports-icon"></i> */}
                                      <span className="name">Parcels</span>
                                    </NavLink>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </li>
                        ) : null}

                        {getAclChecks("manage-building-users") ||
                        getAclChecks("create-tenant-user") ? (
                          <li className="dropdown-item level-three-menu">
                            <button
                              className="dropdown-link  dropdown-toggle collapsed"
                              data-toggle="collapse"
                              data-target={`#users${JSON.parse(
                                sessionStorage.getItem("buildingId")
                              )}`}
                            >
                              <i className="icon-2 bu-icon"></i>
                              <span className="name">Users & Tenants</span>
                            </button>
                            <div
                              id={`users${JSON.parse(
                                sessionStorage.getItem("buildingId")
                              )}`}
                              className="d-menu collapse"
                            >
                              <ul>
                                {getAclChecks("manage-building-users") ? (
                                  <li className="dropdown-item">
                                    <NavLink
                                      to="/dashboard/building-users"
                                      className="dropdown-link"
                                      activeClassName="active"
                                    >
                                      <span className="name">
                                        Building Users
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}

                                {getAclChecks("create-tenant-user") ? (
                                  <li className="dropdown-item">
                                    <NavLink
                                      to="/dashboard/building-tenants"
                                      className="dropdown-link"
                                      activeClassName="active"
                                    >
                                      <span className="name">
                                        Building Tenants
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {getAclChecks("manage-visitors") ||
                                getAclChecks("manage-visitors") ||
                                getAclChecks("view-visitors") ? (
                                  <li className="dropdown-item">
                                    <NavLink
                                      to="/dashboard/events-visitors"
                                      className="dropdown-link"
                                      activeClassName="active"
                                    >
                                      {/* <i className="icon-2 doc-icon"></i> */}
                                      <span className="name">Visitors</span>
                                    </NavLink>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </li>
                        ) : null}
                        {/* <li className="dropdown-item">
                          <NavLink
                            to="/dashboard/bookings"
                            className={`dropdown-link ${pathname == "/dashboard/bookings"
                              ? "active"
                              : pathname == "/dashboard/resources"
                                ? "active"
                                : pathname == "/dashboard/manage-bookings"
                                  ? "active"
                                  : ""
                              }`}
                          >
                            //<span className="dropdown-link">
                            <i className="icon-2 booking-icon"></i>
                            <span className="name">Bookable Spaces</span>
                          </NavLink>
                          //</span>
                        </li> */}

                        {getAclChecks("manage-bookings") ||
                        getAclChecks("manage-bookable-space") ? (
                          <li className="dropdown-item">
                            <button
                              className="dropdown-link dropdown-toggle collapsed"
                              data-toggle="collapse"
                              data-target={`#bs${JSON.parse(
                                sessionStorage.getItem("bookableId")
                              )}`}
                            >
                              <i className="icon-2 build-icon"></i>
                              <span className="name">Bookable Spaces</span>
                            </button>
                            <div
                              id={`bs${JSON.parse(
                                sessionStorage.getItem("bookableId")
                              )}`}
                              className="d-menu collapse"
                            >
                              <ul>
                                {getAclChecks("manage-bookings") ? (
                                  <li className="dropdown-item">
                                    <NavLink
                                      to="/dashboard/manage-bookings"
                                      className="dropdown-link"
                                      activeClassName="active"
                                    >
                                      <span className="name">Bookings</span>
                                    </NavLink>
                                  </li>
                                ) : null}
                                {getAclChecks("manage-bookable-space") ? (
                                  <li className="dropdown-item">
                                    <NavLink
                                      to="/dashboard/bookings"
                                      className="dropdown-link"
                                      activeClassName="active"
                                    >
                                      <span className="name">
                                        Bookable Space
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </li>
                        ) : null}

                        {getAclChecks("manage-alarms") ? (
                          <li className="dropdown-item">
                            <NavLink
                              to={`/dashboard/alarm?buildingId=${obj?.id}`}
                              className="dropdown-link"
                              activeClassName="active"
                            >
                              <i className="icon-2 clock-icon"></i>
                              <span className="name">Alarms</span>
                            </NavLink>
                          </li>
                        ) : null}

                        {getAclChecks("manage-building-systems") ? (
                          <li className="dropdown-item level-two-menu">
                            <button
                              className="dropdown-link dropdown-toggle collapsed"
                              data-toggle="collapse"
                              data-target={`#bs${JSON.parse(
                                sessionStorage.getItem("buildingId")
                              )}`}
                            >
                              <i className="icon-2 build-icon"></i>
                              <span className="name">Building System</span>
                            </button>

                            <div
                              id={`bs${JSON.parse(
                                sessionStorage.getItem("buildingId")
                              )}`}
                              className="d-menu collapse"
                            >
                              <ul>
                                {/* <li className="dropdown-item level-three-menu">
                                  <button className="dropdown-link  dropdown-toggle collapsed" data-toggle="collapse" data-target={`#mech${(JSON.parse(sessionStorage.getItem('buildingId')))}`}>
                                    <NavLink to="/dashboard/building-system/mechanical/summary" className="dropdown-link" activeClassName="active">
                                      <span className="name">Mechanical </span>
                                    </NavLink>
                                  </button>
                                  <div id={`mech${(JSON.parse(sessionStorage.getItem('buildingId')))}`} className="d-menu collapse show">
                                    <ul>
                                      <li className="dropdown-item">
                                        <NavLink to="/dashboard/building-system/mechanical/summary" className="dropdown-link" activeClassName="active">
                                          <span className="name">Summary</span>
                                        </NavLink>
                                      </li>
                                      <li className="dropdown-item">
                                        <span className="dropdown-link">
                                          <span className="name">Split Units</span>
                                        </span>
                                      </li>
                                      <li className="dropdown-item">
                                        <span className="dropdown-link">
                                          <span className="name">FAN’S</span>
                                        </span>
                                      </li>
                                      <li className="dropdown-item">
                                        <NavLink to="/dashboard/building-system/mechanical/vav/summary" className="dropdown-link" activeClassName="active">
                                          <span className="name">VAV’S</span>
                                        </NavLink>
                                      </li>
                                      <li className="dropdown-item">
                                        <span className="dropdown-link">
                                          <span className="name">FCU’S</span>
                                        </span>
                                      </li>
                                      <li className="dropdown-item">
                                        <NavLink to="/dashboard/building-system/mechanical/ahu" className="dropdown-link" activeClassName="active">
                                          <span className="name">AHU'S</span>
                                        </NavLink>
                                      </li>
                                      <li className="dropdown-item">
                                        <NavLink to="/dashboard/building-system/mechanical/chillers" className="dropdown-link" activeClassName="active">
                                          <span className="name">Chillers</span>
                                        </NavLink>
                                      </li>
                                      <li className="dropdown-item">
                                        <span className="dropdown-link">
                                          <span className="name">Boilers</span>
                                        </span>
                                      </li>
                                      <li className="dropdown-item">
                                        <span className="dropdown-link">
                                          <span className="name">Cooling Towers</span>
                                        </span>
                                      </li>
                                      <li className="dropdown-item">
                                        <span className="dropdown-link">
                                          <span className="name">Environmental Sensors</span>
                                        </span>
                                      </li>
                                      <li className="dropdown-item">
                                        <span className="dropdown-link">
                                          <span className="name">Weather Station</span>
                                        </span>
                                      </li>
                                      <li className="dropdown-item">
                                        <span className="dropdown-link">
                                          <span className="name">Hydraulic Pumps & Tanks</span>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </li> */}
                                {/* <li className="dropdown-item level-three-menu">
                                  <button className="dropdown-link dropdown-toggle collapsed" data-toggle="collapse" data-target={`#elec${(JSON.parse(sessionStorage.getItem('buildingId')))}`}>

                                    <NavLink to="/dashboard/building-system/electrical/summary" className="dropdown-link" activeClassName="active">
                                      <span className="name">Electrical</span>
                                    </NavLink>
                                  </button>

                                  <div id={`elec${(JSON.parse(sessionStorage.getItem('buildingId')))}`} className="d-menu collapse">
                                    <ul>
                                      <li className="dropdown-item">
                                        <NavLink to="/dashboard/building-system/electrical/summary" className="dropdown-link" activeClassName="active">
                                          <span className="name">Summary</span>
                                        </NavLink>
                                      </li>
                                      <li className="dropdown-item">
                                        <NavLink to="/dashboard/building-system/electrical/generator" className="dropdown-link" activeClassName="active">
                                          <span className="name">Generator</span>
                                        </NavLink>
                                      </li>

                                      <li className="dropdown-item">
                                        {/* <NavLink to="/dashboard/building-system/electrical/summary" className="dropdown-link" activeClassName=""> */}
                                {/* <span className="dropdown-link">
                                          <span className="name">Power Factor</span> */}
                                {/* </NavLink> */}
                                {/* </span>
                                      </li> */}

                                {/* <li className="dropdown-item">
                                        <NavLink to="/dashboard/building-system/electrical/solarPV" className="dropdown-link" activeClassName="active">
                                          <span className="name">Solar PV</span>
                                        </NavLink>
                                      </li> */}

                                {/* <li className="dropdown-item"> */}
                                {/* <NavLink to="/dashboard/building-system/electrical/summary" className="dropdown-link" activeClassName=""> */}
                                {/* <span className="dropdown-link">
                                          <span className="name">Building UPS</span> */}
                                {/* </NavLink> */}
                                {/* </span>
                                      </li> */}

                                {/* <li className="dropdown-item"> */}
                                {/* <NavLink to="/dashboard/building-system/electrical/summary" className="dropdown-link" activeClassName=""> */}
                                {/* <span className="dropdown-link">
                                          <span className="name">Lighting Control</span>
                                        </span> */}
                                {/* </NavLink> */}
                                {/* </li> */}

                                {/* <li className="dropdown-item"> */}
                                {/* <NavLink to="/dashboard/building-system/electrical/summary" className="dropdown-link" activeClassName=""> */}
                                {/* <span className="dropdown-link">
                                          <span className="name">Exit & Emergency</span>
                                        </span> */}
                                {/* </NavLink> */}
                                {/* </li>

                                    </ul>

                                  </div> */}

                                {/* </li> */}

                                <li className="dropdown-item">
                                  <NavLink
                                    to="/dashboard/building-system/solarPV"
                                    className="dropdown-link"
                                    activeClassName="active"
                                  >
                                    <span className="name">Solar PV</span>
                                  </NavLink>
                                </li>
                                <li className="dropdown-item">
                                  <NavLink
                                    to="/dashboard/building-system/electrical/summary"
                                    className="dropdown-link"
                                    activeClassName="active"
                                  >
                                    <span className="dropdown-link">
                                      <span className="name">Power</span>
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="dropdown-item">
                                  <NavLink
                                    to="/dashboard/building-system/electrical/generator"
                                    className="dropdown-link"
                                    activeClassName="active"
                                  >
                                    <span className="dropdown-link">
                                      <span className="name">Water</span>
                                    </span>
                                  </NavLink>
                                </li>
                                {/* <li className="dropdown-item level-three-menu">
                                  <button
                                    className="dropdown-link dropdown-toggle collapsed"
                                    data-toggle="collapse"
                                    data-target={`#mete${JSON.parse(
                                      sessionStorage.getItem("buildingId")
                                    )}`}
                                  > */}
                                {/* <NavLink
                                      to="/dashboard/building-system/metering"
                                      className="dropdown-link"
                                      activeClassName="active"
                                    >
                                      <span className="name">Metering</span>
                                    </NavLink> */}
                                {/* </button> */}

                                {/* <div
                                    id={`mete${JSON.parse(
                                      sessionStorage.getItem("buildingId")
                                    )}`}
                                    className="d-menu collapse"
                                  > */}
                                {/* <ul> */}
                                {/* <li className="dropdown-item"> */}
                                {/* <NavLink to="/dashboard/building-system/electrical/summary" className="dropdown-link" activeClassName="active"> */}
                                {/* <span className="dropdown-link">
                                          <span className="name">Power</span>
                                        </span> */}
                                {/* </NavLink> */}
                                {/* </li> */}
                                {/* <li className="dropdown-item"> */}
                                {/* <NavLink to="/dashboard/building-system/electrical/generator" className="dropdown-link" activeClassName="active"> */}
                                {/* <span className="dropdown-link">
                                          <span className="name">Water</span>
                                        </span> */}
                                {/* </NavLink> */}
                                {/* </li> */}

                                {/* <li className="dropdown-item"> */}
                                {/* <NavLink to="/dashboard/building-system/electrical/summary" className="dropdown-link" activeClassName=""> */}
                                {/* <span className="dropdown-link">
                                          <span className="name">Gas</span> */}
                                {/* </NavLink> */}
                                {/* </span>
                                      </li> */}

                                {/* <li className="dropdown-item"> */}
                                {/* <NavLink to="/dashboard/building-system/electrical/solarPV" className="dropdown-link" activeClassName="active"> */}
                                {/* <span className="dropdown-link">
                                          <span className="name">Thermal</span>
                                        </span> */}
                                {/* </NavLink> */}
                                {/* </li> */}
                                {/* </ul> */}
                                {/* </div> */}
                                {/* </li> */}

                                <li className="dropdown-item">
                                  <NavLink
                                    className={`dropdown-link ${
                                      pathname ==
                                      "/dashboard/building-system/iaq"
                                        ? "active"
                                        : ""
                                    }`}
                                    to={`/dashboard/building-system/iaq?buildingId=${obj?.id}`}
                                    activeClassName="active"
                                  >
                                    <span className="dropdown-link">
                                      <span className="name">IAQ Page</span>
                                    </span>
                                  </NavLink>
                                </li>

                                {/* <li className="dropdown-item"> */}
                                {/* <NavLink to="/dashboard/building-system/mechanical" className="dropdown-link" activeClassName=""> */}
                                {/* <span className="dropdown-link">
                                    <span className="name">Security</span>
                                  </span> */}
                                {/* </NavLink> */}
                                {/* </li> */}

                                {/* <li className="dropdown-item"> */}
                                {/* <NavLink to="/dashboard/building-system/mechanical" className="dropdown-link" activeClassName=""> */}
                                {/* <span className="dropdown-link">
                                    <span className="name">Fire Protection</span> */}
                                {/* </NavLink> */}
                                {/* </span>
                                </li> */}
                                {/* <li className="dropdown-item">
                                      <span className="dropdown-link">
                                      <span className="name">Lifts</span>
                                   </span>
                                   </li> */}
                                {/* <li className="dropdown-item">
                                  <NavLink
                                    to="/dashboard/building-system/lifts"
                                    activeClassName="active"
                                  >
                                    <button
                                      className="dropdown-link  dropdown-toggle collapsed"
                                      data-toggle="collapse"
                                      data-target={`#lift${JSON.parse(
                                        sessionStorage.getItem("buildingId")
                                      )}`}
                                    >
                                      <span className="name">Lifts</span>
                                    </button>
                                  </NavLink>
                                  <div
                                    id={`lift${JSON.parse(
                                      sessionStorage.getItem("buildingId")
                                    )}`}
                                    className="d-menu collapse"
                                  >
                                    <ul>
                                      <li className="dropdown-item">
                                        <NavLink
                                          to="/dashboard/building-system/lifts/1"
                                          className="dropdown-link"
                                          activeClassName="active"
                                        >
                                          <span className="name">Lift 1</span>
                                        </NavLink>
                                      </li>
                                    </ul>
                                  </div>
                                </li> */}
                                <li className="dropdown-item">
                                  <NavLink
                                    to="/dashboard/building-system/car-parking"
                                    className="dropdown-link"
                                    activeClassName="active"
                                  >
                                    <span className="name">Car Parking</span>
                                  </NavLink>
                                </li>
                                <li className="dropdown-item">
                                  <a
                                    className="dropdown-link"
                                    href="generator.html"
                                  >
                                    <span className="name">
                                      People Counting
                                    </span>
                                  </a>
                                </li>
                                {/* <li className="dropdown-item level-three-menu">
                                  <button
                                    className="dropdown-link  dropdown-toggle collapsed"
                                    data-toggle="collapse"
                                    data-target={`#oper${JSON.parse(
                                      sessionStorage.getItem("buildingId")
                                    )}`}
                                  >
                                    <span className="name">Operations </span>
                                  </button>
                                  <div
                                    id={`oper${JSON.parse(
                                      sessionStorage.getItem("buildingId")
                                    )}`}
                                    className="d-menu collapse"
                                  >
                                    <ul>
                                      <li className="dropdown-item">
                                        <NavLink
                                          to="/dashboard/building-system/operations/car-parking"
                                          className="dropdown-link"
                                          activeClassName="active"
                                        >
                                          <span className="name">
                                            Car Parking
                                          </span>
                                        </NavLink>
                                      </li>
                                      <li className="dropdown-item">
                                        <a
                                          className="dropdown-link"
                                          href="generator.html"
                                        >
                                          <span className="name">
                                            People Counting
                                          </span>
                                        </a>
                                      </li>
                                      <li className="dropdown-item">
                                        <a className="dropdown-link" href="">
                                          <span className="name">
                                            Building Occupancy
                                          </span>
                                        </a>
                                      </li>
                                      <li className="dropdown-item">
                                        <a className="dropdown-link" href="">
                                          <span className="name">
                                            EV Chargers
                                          </span>
                                        </a>
                                      </li>
                                      <li className="dropdown-item">
                                        <a className="dropdown-link" href="">
                                          <span className="name">Waste</span>
                                        </a>
                                      </li>
                                      <li className="dropdown-item">
                                        <a className="dropdown-link" href="">
                                          <span className="name">WIFI</span>
                                        </a>
                                      </li>
                                      <li className="dropdown-item">
                                        {/* <a className="dropdown-link" href="digital-display.html"> */}
                                {/* <NavLink
                                          to="/dashboard/building-system/operations/digital-display"
                                          className="dropdown-link"
                                          activeClassName="active"
                                        >
                                          <span className="name">
                                            Digital Displays
                                          </span>
                                        </NavLink> */}
                                {/* </a> */}
                                {/* </li>
                                    </ul>
                                  </div>
                                </li> */}
                              </ul>
                            </div>
                          </li>
                        ) : null}
                        {/* <li className="dropdown-item">
                                       <NavLink to="/dashboard/floor-plan" className="dropdown-link" activeClassName="">
                                          <i className="icon-2 floor-icon"></i>
                                          <span className="name">Floor Plan</span>
                                       </NavLink>
                                    </li> */}

                        {getAclChecks("manage-floors") ? (
                          <li className="dropdown-item level-three-menu">
                            <button
                              className="dropdown-link  dropdown-toggle collapsed"
                              data-toggle="collapse"
                              data-target={`#floor${JSON.parse(
                                sessionStorage.getItem("buildingId")
                              )}`}
                            >
                              <i className="icon-2 floor-icon"></i>
                              <span className="name">Floors</span>
                            </button>
                            <div
                              id={`floor${JSON.parse(
                                sessionStorage.getItem("buildingId")
                              )}`}
                              className="d-menu collapse"
                            >
                              <ul>
                                <li className="dropdown-item">
                                  <NavLink
                                    to="/dashboard/floor-plan"
                                    className="dropdown-link"
                                    activeClassName="active"
                                  >
                                    <span className="name">Floor Plan</span>
                                  </NavLink>
                                </li>
                                <li className="dropdown-item">
                                  <NavLink
                                    to="/dashboard/manage-asset"
                                    className="dropdown-link"
                                    activeClassName="active"
                                  >
                                    <span className="name">Manage Asset</span>
                                  </NavLink>
                                </li>
                                {/*
                                               <li className="dropdown-item">
                                                  <NavLink to="/dashboard/floor-plan" className="dropdown-link" activeClassName="active">
                                                     <span className="name">Floor Management</span>
                                                  </NavLink>
                                               </li> */}

                                <li className="dropdown-item">
                                  <NavLink
                                    to="/dashboard/floor-settings"
                                    className="dropdown-link"
                                    activeClassName="active"
                                  >
                                    <span className="name">Settings</span>
                                  </NavLink>
                                </li>
                              </ul>
                            </div>
                          </li>
                        ) : null}

                        {getAclChecks("manage-schedules") ? (
                          <li className="dropdown-item level-three-menu">
                            <button
                              className="dropdown-link  dropdown-toggle collapsed"
                              data-toggle="collapse"
                              data-target={`#schedule${JSON.parse(
                                sessionStorage.getItem("buildingId")
                              )}`}
                            >
                              <i className="icon-2 cal-icon"></i>
                              <span className="name">Schedules</span>
                            </button>
                            <div
                              id={`schedule${JSON.parse(
                                sessionStorage.getItem("buildingId")
                              )}`}
                              className="d-menu collapse"
                            >
                              <ul>
                                <li className="dropdown-item">
                                  <NavLink
                                    to="/dashboard/maintenance-schedule"
                                    className="dropdown-link"
                                    activeClassName="active"
                                  >
                                    <span className="name">
                                      Maintenance Schedule
                                    </span>
                                  </NavLink>
                                </li>

                                {/* <li className="dropdown-item">
                                  <NavLink
                                    to="/dashboard/building-schedule"
                                    className="dropdown-link"
                                    activeClassName="active"
                                  >
                                    <span className="name">
                                      Building Schedule
                                    </span>
                                  </NavLink>
                                </li> */}

                                <li className="dropdown-item">
                                  <NavLink
                                    to="/dashboard/building-system/operations/assetRegister"
                                    className="dropdown-link"
                                    activeClassName="active"
                                  >
                                    <span className="name">Asset Register</span>
                                  </NavLink>
                                </li>
                              </ul>
                            </div>
                          </li>
                        ) : null}
                        {getAclChecks("manage-analytics") ? (
                          <li className="dropdown-item">
                            <NavLink
                              to="/dashboard/analytics"
                              className="dropdown-link"
                              activeClassName="active"
                            >
                              <i className="icon-2 analytics-icon"></i>
                              <span className="name">Analytics</span>
                            </NavLink>
                          </li>
                        ) : null}

                        {getAclChecks("manage-reports") ||
                        getAclChecks("view-tenant-report") ? (
                          <li className="dropdown-item">
                            <NavLink
                              to="/dashboard/reports"
                              className="dropdown-link"
                              activeClassName="active"
                            >
                              <i className="icon-2 reports-icon"></i>
                              <span className="name">Reports</span>
                            </NavLink>
                          </li>
                        ) : null}

                        {/* parcels */}
                        {/* <li className="dropdown-item">
                          <NavLink to="/dashboard/parcels" className="dropdown-link" activeClassName="active">
                            <i className="icon-2 reports-icon"></i>
                            <span className="name">Parcels</span>
                          </NavLink>
                        </li> */}

                        {getAclChecks("manage-tickets") ||
                        getAclChecks("view-tenant-tickets") ? (
                          <li className="dropdown-item">
                            <NavLink
                              to="/dashboard/tickets"
                              className="dropdown-link"
                              activeClassName="active"
                            >
                              <i className="icon-2 ticket-icon"></i>
                              <span className="name">Tickets</span>
                            </NavLink>
                          </li>
                        ) : null}

                        {getAclChecks("manage-information") ||
                        getAclChecks("manage-informations") ||
                        getAclChecks("view-tenant-information") ? (
                          <li className="dropdown-item">
                            <NavLink
                              to="/dashboard/information"
                              className="dropdown-link"
                              activeClassName="active"
                            >
                              <i className="icon-2 info-icon"></i>
                              <span className="name">Information</span>
                            </NavLink>
                          </li>
                        ) : null}

                        {getAclChecks("manage-documents") ||
                        getAclChecks("manage-documents") ||
                        getAclChecks("view-tenant-documents") ? (
                          <li className="dropdown-item">
                            <NavLink
                              to="/dashboard/documents"
                              className="dropdown-link"
                              activeClassName="active"
                            >
                              <i className="icon-2 doc-icon"></i>
                              <span className="name">Documents</span>
                            </NavLink>
                          </li>
                        ) : null}

                        {getAclChecks("manage-settings") ||
                        getAclChecks("manage-floor-plan-settings") ? (
                          <li className="dropdown-item">
                            <NavLink
                              to={`/dashboard/manage-others?id=${obj?.id}`}
                              className="dropdown-link"
                              activeClassName="active"
                            >
                              <i className="icon-2 setting-icon"></i>
                              <span className="name">Settings</span>
                            </NavLink>
                          </li>
                        ) : null}

                        <li className="dropdown-item">
                          <NavLink
                            to={`/dashboard/chat?buildingId=${obj.id}`}
                            className="dropdown-link"
                            activeClassName="active"
                          >
                            <i className="icon-2 message-icon"></i>
                            <span className="name">Message</span>
                          </NavLink>
                        </li>

                        {getAclChecks("admin") || getAclChecks("client") ? (
                          <li className="dropdown-item">
                            <NavLink
                              to={`/dashboard/transaction-payment?buildingId=${obj?.id}`}
                              className="dropdown-link"
                              activeClassName="active"
                            >
                              <i className="icon-2 transactions-icon"></i>
                              <i class="bi bi-credit-card-2-front"></i>
                              <span className="name">Transactions</span>
                            </NavLink>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </li>
                ))}

              {/*
                <li className="nav-item" onClick={() => this.newPage()}>
                  <NavLink className="nav-link" to="/dashboard/roles">
                     <i className="icon-2 setting-icon"></i>
                     <span className="name">Roles</span>
                  </NavLink>
               </li>
               <li className="nav-item" onClick={() => this.newPage()}>
                  <NavLink className="nav-link" to="/dashboard/settings">
                     <i className="icon-2 setting-icon"></i>
                     <span className="name">Settings</span>
                  </NavLink>
               </li> 
               */}
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBuldingSuccess: (payload) => {
      dispatch(getBuldingSuccess(payload));
    },
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Sidebar));
