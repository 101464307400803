import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { ROUTES } from "../routes";
import { withRouter } from "react-router-dom";
import ContentLoader from "./ContentLoader";
import Header from "./header";
import Sidebar from "./sidebar";
import io from "socket.io-client";
import { BASE_URL_IO } from "../constants";
import ACLRoute from "../ACLRoute";
import { authentication } from "../utilities";
import querystring from "query-string";
import {
  appendChatMessage,
  updateChatMessage,
  replyChatMessage,
  onlineMember,
  singlethreadChatMember,
  getsingleMemberList,
  MemberAlreadyInGroup,
  updateMessageCount,
  increaseMessageCount,
  getAllUnreadMessageCount,
  appendNotificationChatMessages,
  sortThreadList,
  getThreadList,
  threadList,
  prependForwardChatMessages,
} from "../actions/chat";
import { ToastContainer } from "react-toastify";
import { prependNotification } from "../actions/notification";
export let socket = null;
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuDisplay: false,
      userAuth: false,
      notificationCount: null,
      abc: 0,
    };
  }
  menuToBeDisplayed = (showMenu) => {
    this.setState({ menuDisplay: showMenu });
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ abc: 100 });
    }, 2000);
    var params = querystring.parse(
      decodeURIComponent(this.props.location.search)
    );
    this.props.history.listen((location) => {
      params = querystring.parse(decodeURIComponent(location.search));
    });
    socket = io(`${BASE_URL_IO}?token=${localStorage.getItem("jwtToken")}`, {
      transports: ["websocket", "polling"],
      reconnection: true,
      forceNew: true,
    });
    socket.on("error", (message) => {
      console.log("error", message);
    });
    socket.on("disconnect", (message) => {
      console.log("dis", message);
    });
    socket.on("connecting", (message) => {
      console.log("connecting", message);
    });
    socket.on("errorfromserver", (message) => {
      console.log("errorfromserver", message);
    });
    socket.onAny((eventName, ...args) => {
      console.log("eeeeeeDashboard", eventName, ...args);
    });
    socket.on("onReceiveMessage", (payload) => {
      if (parseInt(params.thread_id) == parseInt(payload.thread_id)) {
        this.props.updateMessageCount({
          thread_id: payload?.thread_id,
        });
        this.props.sortThreadList({
          thread_id: payload.thread_id,
          payload: payload?.message,
        });
      } else {
        this.props.sortThreadList({
          thread_id: payload.thread_id,
          payload: payload?.message,
        });
        this.props.increaseMessageCount({
          thread_id: payload.thread_id,
        });
      }
      if (params.thread_id != payload?.thread_id) return;
      this.props.appendChatMessage({
        thread_id: payload.thread_id,
        payload: payload?.message,
      });
      setTimeout(() => {
        const chatList = document.querySelector(".chat-area-main");
        if (chatList != null) {
          chatList.scrollTop = chatList.scrollHeight;
        }
      }, 100);
    });
    socket.on("userLeft", (payload) => {
      if (parseInt(params.thread_id) != parseInt(payload.thread_id)) return;
      this.props.appendChatMessage({
        thread_id: payload.thread_id,
        payload: payload?.message,
      });
      singlethreadChatMember({ thread_id: parseInt(params.thread_id) })
        .then((res) => {
          if (res?.data?.responseData?.threadDetails?.type == "individual") {
          } else {
            this.props.MemberAlreadyInGroup(
              res?.data?.responseData?.members?.rows?.filter(
                (group) => group.status == "joined"
              )
            );
          }
        })
        .catch((err) => {});
      setTimeout(() => {
        const chatList = document.querySelector(".chat-area-main");
        if (chatList != null) {
          chatList.scrollTop = chatList.scrollHeight;
        }
      }, 100);
    });
    socket.on("deletedMessage", (payload) => {
      if (parseInt(params.thread_id) != parseInt(payload.thread_id)) return;
      this.props.appendChatMessage({
        thread_id: payload.thread_id,
        payload: payload?.message,
      });

      setTimeout(() => {
        const chatList = document.querySelector(".chat-area-main");
        if (chatList != null) {
          chatList.scrollTop = chatList.scrollHeight;
        }
      }, 100);
    });
    socket.on("updatedMessage", (payload) => {
      if (parseInt(params.thread_id) != parseInt(payload.thread_id)) return;
      this.props.updateChatMessage({
        thread_id: payload.thread_id,
        payload: payload?.message,
      });
      setTimeout(() => {
        const chatList = document.querySelector(".chat-area-main");
        if (chatList != null) {
          chatList.scrollTop = chatList.scrollHeight;
        }
      }, 100);
    });
    socket.on("forwardedMessage", (payload) => {
      this.props.sortThreadList({
        thread_id: payload.thread_id,
        payload: payload?.message,
      });
      if (parseInt(params.thread_id) != parseInt(payload.thread_id)) {
        this.props.increaseMessageCount({
          thread_id: payload.thread_id,
        });
      } else {
        this.props.updateMessageCount({
          thread_id: payload?.thread_id,
        });
        this.props.appendChatMessage({
          thread_id: payload.thread_id,
          payload: payload?.message,
        });

        setTimeout(() => {
          const chatList = document.querySelector(".chat-area-main");
          if (chatList != null) {
            chatList.scrollTop = chatList.scrollHeight;
          }
        }, 100);
      }
    });
    socket.on("replied", (payload) => {
      this.props.sortThreadList({
        thread_id: payload.thread_id,
        payload: payload?.message,
      });
      if (parseInt(params.thread_id) == parseInt(payload.thread_id)) {
        this.props.updateMessageCount({
          thread_id: payload?.thread_id,
        });
      } else {
        if (this.props.threadList.length > 0) {
          this.props.increaseMessageCount({
            thread_id: payload.thread_id,
          });
        }
      }
      if (parseInt(params.thread_id) != parseInt(payload.thread_id)) return;
      this.props.replyChatMessage({
        thread_id: payload.thread_id,
        payload: payload?.message,
      });
      this.props.increaseMessageCount({
        thread_id: payload.thread_id,
      });
      setTimeout(() => {
        const chatList = document.querySelector(".chat-area-main");
        if (chatList != null) {
          chatList.scrollTop = chatList.scrollHeight;
        }
      }, 100);
    });
    socket.on("reacted", (payload) => {
      if (parseInt(params.thread_id) != parseInt(payload.thread_id)) return;
      this.props.appendChatMessage({
        thread_id: payload.thread_id,
        payload: payload?.message,
      });

      setTimeout(() => {
        const chatList = document.querySelector(".chat-area-main");
        if (chatList != null) {
          chatList.scrollTop = chatList.scrollHeight;
        }
      }, 100);
    });
    socket.on("onlineStatus", (payload) => {
      this.props.onlineMember(payload);
    });
    socket.on("userKicked", (payload) => {
      if (parseInt(params.thread_id) != parseInt(payload.thread_id)) return;
      this.props.appendChatMessage({
        thread_id: payload.thread_id,
        payload: payload?.message,
      });
      singlethreadChatMember({ thread_id: parseInt(params.thread_id) })
        .then((res) => {
          if (res?.data?.responseData?.threadDetails?.type == "individual") {
          } else {
            this.props.MemberAlreadyInGroup(
              res?.data?.responseData?.members?.rows?.filter(
                (group) => group.status == "joined"
              )
            );
          }
        })
        .catch((err) => {});
      setTimeout(() => {
        const chatList = document.querySelector(".chat-area-main");
        if (chatList != null) {
          chatList.scrollTop = chatList.scrollHeight;
        }
      }, 100);
    });
    socket.on("notification", (payload) => {
      this.props.appendNotificationChatMessages(payload);
      this.props.getAllUnreadMessageCount(this.props.chatNotificationCount + 1);
    });
    socket.on("booking_notification", (payload) => {
      console.log(`booking_notification`, payload);
      this.props.prependNotification(payload);
      // this.props.appendNotificationChatMessages(payload)
      // this.props.getAllUnreadMessageCount(this.props.chatNotificationCount + 1)
    });
    this.getNotificationCount(
      JSON.parse(localStorage.getItem("auth"))?.User?.id
    );
    socket.on("userAdded", (payload) => {
      if (parseInt(params.thread_id) != parseInt(payload.thread_id)) return;
      if (payload?.message != null) {
        this.props.appendChatMessage({
          thread_id: payload.thread_id,
          payload: payload?.message,
        });

        setTimeout(() => {
          const chatList = document.querySelector(".chat-area-main");
          if (chatList != null) {
            chatList.scrollTop = chatList.scrollHeight;
          }
        }, 100);
      }
    });
  }
  getNotificationCount(payload) {
    return new Promise((resolve, reject) => {
      socket.emit("getNotificationCount", payload, null, (message) => {
        this.setState({
          notificationCount: message?.responseData?.count,
        });
        if (message?.error) {
          reject(message.error);
        } else {
          resolve(message);
        }
      });
    });
  }
  render() {
    if (!authentication()) {
      return <Redirect to="/login" />;
    }
    if (!sessionStorage.getItem("buildingId")) {
      return <Redirect to="/landing" />;
    }
    return (
      <div>
        {/* <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={50000} /> */}
        <Header
          abc={this.state.abc}
          menuToBeDisplayedCallback={this.menuToBeDisplayed}
          menuDisplay={this.state.menuDisplay}
          notificationCount={this.state.notificationCount}
        />
        <Sidebar menuDisplay={this.state.menuDisplay} />
        <Suspense fallback={<ContentLoader />}>
          <Route {...ROUTES.OVERVIEW} />
          <Route {...ROUTES.ALARM} />
          <Route {...ROUTES.ALARMSETTINGS} />
          <Route {...ROUTES.BLOGNEWS} />
          <Route {...ROUTES.BLOGDETAILS} />
          <Route {...ROUTES.EVENTDETAILS} />
          <Route {...ROUTES.BUILDINGSCHEDULE} />
          <Route {...ROUTES.BUILDINGUSERS} />
          <Route {...ROUTES.BUILDINGTENANTS} />
          <Route {...ROUTES.PERKS} />
          <Route {...ROUTES.PERKSDETAIL} />
          <Route {...ROUTES.SHOPS} />
          <Route {...ROUTES.TENANTHANDBOOK} />
          <Route {...ROUTES.TENANTHANDBOOKDETAIL} />
          <Route {...ROUTES.BOOKINGS} />
          <Route {...ROUTES.REPORTS} />
          <Route {...ROUTES.PARCELS} />
          <Route {...ROUTES.TICKETS} />
          <Route {...ROUTES.TICKETSSETTINGS} />
          <Route {...ROUTES.INFORMATION} />
          <Route {...ROUTES.DOCUMENTS} />

          <Route {...ROUTES.ANALYTICS} />
          <Route {...ROUTES.POLL} />
          <Route {...ROUTES.EVENT} />
          <Route {...ROUTES.ELECTRICAL} />
          <Route {...ROUTES.IAQ} />
          <Route {...ROUTES.GENERATOR} />
          <Route {...ROUTES.METERING} />
          <Route {...ROUTES.SOLARPV} />
          <Route {...ROUTES.ASSETREGISTER} />
          <Route {...ROUTES.ASSETREGISTERDETAIL} />
          <Route {...ROUTES.EDITASSETREGISTERDETAIL} />
          <Route {...ROUTES.DIGITALDISPLAY} />
          <Route {...ROUTES.MECHANICAL} />
          <Route {...ROUTES.LIFT1} />
          <Route {...ROUTES.VAV} />
          <Route {...ROUTES.FLOORPLAN} />
          <Route {...ROUTES.FLOORSETTINGS} />
          <Route {...ROUTES.MAINTENANCESCHEDULE} />
          <Route {...ROUTES.LIFTSYSTEM} />
          <Route {...ROUTES.CARPARKING} />
          <Route {...ROUTES.MANAGEBOOKINGS} />
          <Route {...ROUTES.MANAGEOTHERS} />
          <Route {...ROUTES.VAVSUMMARY} />
          <Route {...ROUTES.RESOURCES} />
          <Route {...ROUTES.AHU} />
          <Route {...ROUTES.AHUSUMMARY} />
          <Route {...ROUTES.NEWSFEED} />
          <Route {...ROUTES.CHILLERS} />
          <Route {...ROUTES.CHATDASHBOARD} />
          <Route {...ROUTES.SETTINGS} />
          <Route {...ROUTES.MANAGEASSET}/>
          <Route {...ROUTES.TRANSACTION}/>
          <Route {...ROUTES.MANAGE_ROLES}/>
           <Route {...ROUTES.VISITORS_VISIT}/>
           <Route {...ROUTES.BUILDINGUSERSVISITORS}/>
           <Route {...ROUTES.VISITORS_EVENT_VIEW}/>

        </Suspense>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    appendChatMessage: (thread_id, payload) => {
      dispatch(appendChatMessage(thread_id, payload));
    },
    updateChatMessage: (thread_id, payload) => {
      dispatch(updateChatMessage(thread_id, payload));
    },
    replyChatMessage: (thread_id, payload) => {
      dispatch(replyChatMessage(thread_id, payload));
    },
    onlineMember: (payload) => {
      dispatch(onlineMember(payload));
    },
    getsinglechatMemberSearch: (payload) => {
      dispatch(getsingleMemberList(payload));
    },
    MemberAlreadyInGroup: (payload) => {
      dispatch(MemberAlreadyInGroup(payload));
    },
    updateMessageCount: (payload) => {
      dispatch(updateMessageCount(payload));
    },
    increaseMessageCount: (payload) => {
      dispatch(increaseMessageCount(payload));
    },
    getAllUnreadMessageCount: (payload) => {
      dispatch(getAllUnreadMessageCount(payload));
    },
    appendNotificationChatMessages: (payload) => {
      dispatch(appendNotificationChatMessages(payload));
    },
    sortThreadList: (thread_id, payload) => {
      dispatch(sortThreadList(thread_id, payload));
    },
    threadList: (payload) => {
      dispatch(threadList(payload));
    },
    prependNotification: (payload) => {
      dispatch(prependNotification(payload));
    },
    //  prependForwardChatMessages: (payload,ForwardMessageId) => {
    //   dispatch(prependForwardChatMessages(payload,ForwardMessageId));
    // },
  };
};
const mapStatesToProps = (state, ownProps) => {
  return {
    chatNotificationCount: state.Chats.chatNotificationCount,
    threadList: state.Chats.threadList,
  };
};

export default withRouter(
  connect(mapStatesToProps, mapDispatchToProps)(Dashboard)
);
